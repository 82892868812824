@import-normalize;

:root {
  --main-bg-color: #000;
  --white:#ffF;
  --grey:#969696;
  --main-color01:#5257CF;
  --main-p-font-size:8pt;
  --h1-fontsize: 18pt;
}
/* Scroll Bar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background-color: #3c3c3c;
  border-radius: 5px;
} 
::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 5px;
}
::-webkit-scrollbar-button {
  background-color: #555;
  height: 0;
}
::-webkit-scrollbar-corner {
  background-color: #555;
}
body{
  margin: 0;
  padding: 0;
  background: #000;
  color:white;
}
ul,li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
a{
  text-decoration: none;
  color:var(--white)
}
.home_header{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
  &.center{
    text-align: center;
    justify-content: center;
  }
  h1{
    font-size: var(--h1-fontsize);
    margin: 0;
  }
  .more{
    font-size: var(--main-p-font-size);
    color:var(--grey);
    font-weight: 100;
  }
  .desc{
    font-size: var(--main-p-font-size);
    color:var(--grey);
    font-weight: 100;
    text-align: right;
  }
}

#home{

  .home_container{
    width: 80%;
    margin:0 auto;
    position: relative;
    margin-top: -150px;
    z-index: 9;
  }
  #Newest{

    .newest_list{
      display: flex;
      width: 100%;

      // justify-content: center;
      // align-items: center;
      &:hover .newest_item:not(:hover){
        filter:brightness(0.5)
      }

      .newest_item{
        flex: 1;
        position: relative;
        margin:0.5%;
        background-repeat: no-repeat;
        background-position:  center;
        background-size: cover;
        transition: 250ms all;
        padding-top:11%;
        cursor: pointer;
        &:first-child {
          margin-left:0;
        }

        &:last-child{
          margin-right: 0;
        }
        &:hover{
          transform: scale(1.2);
          opacity: 1;
          margin:0 1.8%;
          z-index: 1;

        }
      
        &:first-child:hover{
          margin:0 1.8%;
        }

        &:last-child:hover{
            margin:0 1.8%;
        }
        .title{
          position: absolute;
          left:5px;
          bottom:5px;
          font-size: 8pt;
        }

      }
    }
  }
  #Home_services{
    .service_list{
      .service_item{
        &.active{
          flex: 10;
          filter: contrast(120%);
        }
        &:before{
          content: '';
          position: absolute;
          top: 0;left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.27);
        }
        
      }
    }
  }
  #home_about{
    .home_about_container{

      .content{

        background: rgba(28, 28, 28, 0.685);
        padding:35px 20px;
        z-index: 1;
        .title{
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 15px;
        }
        .desc{
          font-size: 13px;
          font-weight: 100;
          color:#ddd;
          line-height: 18px;
        }
      }
      .image_cover{
        margin-left: 150px;
        padding-top:25%;
        position: relative;
        background-repeat: no-repeat;
        background-position:  center;
        background-size: cover;
      }
    }
  }
  #award{
    .award_list{

      li{
        img{
          width: 100px;
        }
        
      }
    }
  }
}


.vimeo-wrapper , .img-wrapper {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  background-repeat: repeat;
    background-position: center center;
  
  &:before{
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    visibility: hidden;

  }
  .react-player{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .imgbg{


  }
}
.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.footer {
  font-family: 'Roboto Slab', "微軟正黑體" ,"Microsoft JhengHei","儷黑 Pro","LiHei Pro","文泉驛正黑", "WenQuanYi Zen Hei",serif;
  color: white;
  font-size: 13px;
  padding-bottom: 25px;
  margin: 45px 45px 0px 45px;
  margin-top: 150px;
  .companyInfo {
    margin-top: 30px;
    font-size: 12px;
    color:#ddd;
    p{
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  .logos {
    line-height: 30px;
    padding-bottom: 15px;
    margin-top: 20px;
    display: flex;
    border-bottom: 1px solid #e5e7eb70;
    .social {
      margin-right: 25px;
      a{
        opacity: 0.8;
        transition: 0.2s;
        img {
          height: 15px;
          vertical-align: bottom;
          transition: 0.2s;
        }
        &:hover{
          opacity: 1;
        }  
      } 
    }
    .mslogo{
      margin-left: auto;
      img{
        width: 137px;
        vertical-align: bottom;
      }
    }
  }
  .copyright {
    font-size: 12px;
    text-align: right;
    margin-top: 5px;
    opacity: 0.5;
  }
}

@import 'header';
@import 'navbar';
@import 'about';